import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import Cx from "../../../style/custom.module.scss";
import DefaultFooter from "examples/Footers/DefaultFooter";
import footerRoutes from "footer.routes";
import Headerhome from "layouts/hearderhome/Headerhome";
import { Link } from "react-router-dom";

function ContactUs() {
  return (
    <>
      <Headerhome />
      <MKBox position="fixed" top="0.5rem" width="100%"></MKBox>
      <div className={`${Cx.sectioncontact}  position-relative`} style={{boxShadow:'none'}}>
        <Grid
          container
          spacing={0}
          alignItems="center"
          className={`${Cx.section_padding} ${Cx.containerarea}`}
        >
          <Grid item lg={6} md={12}>
            <div className={Cx.imagesidesignup}>
              <div className={Cx.contacttext}>
                <h2>Contact Us</h2>
                <p>
                  For all inquiries, please contact{" "}
                  <Link className={Cx.linka}>hello@agfinancehub.com.</Link> Our team aims to respond
                  within 24 hours. Kindly provide as much detail as possible to help us assist you
                  effectively.
                </p>
              </div>
            </div>
          </Grid>
          <Grid item lg={6} md={12}>
            <div className={Cx.contactforms}>
              {/*  <MKBox
                variant="gradient"
                coloredShadow="info"
                borderRadius="lg"
                p={2}
                mx={4}
                mt={-3}
                className={Cx.contactheading}
              >
                <MKTypography className={Cx.heading_textarea}>
                  <h2>Contact us</h2>
                </MKTypography>
              </MKBox> */}
              <MKBox p={3}>
                {/*  <MKTypography variant="body2" color="text" mb={3}>
                  For further questions, including partnership opportunities, please email
                  hello@creative-tim.com or contact using our contact form.
                </MKTypography> */}
                <MKBox width="100%" component="form" method="post" autoComplete="off">
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <MKInput
                        variant="standard"
                        label="Full Name"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <MKInput
                        type="email"
                        variant="standard"
                        label="Email"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <MKInput
                        type="tel"
                        variant="standard"
                        label="Phone"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <MKInput
                        variant="standard"
                        label="Subject"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                      />
                    </Grid>
                    {/*    <Grid item xs={12}>
                      <MKInput
                        variant="standard"
                        label="Description"
                        placeholder="Describe your problem in at least 250 characters"
                        InputLabelProps={{ shrink: true }}
                        multiline
                        fullWidth
                        rows={6}
                      />
                    </Grid> */}
                  </Grid>

                  <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
                    <div className={Cx.mainheading}>
                      <button type="submit" className={Cx.submitButton_contact}>
                        Send Message
                      </button>
                    </div>
                  </Grid>
                </MKBox>
              </MKBox>
            </div>
          </Grid>
        </Grid>
        <div className="container-fluid px-0 mx-0 pt-5">
          <div className={Cx.bannertopimage1}>
            <div className={Cx.paddingarea1}></div>
          </div>
        </div>
      </div>
      <div style={{ bgColor: "#ffff", padding: "0px 0px", marginTop: "0px" }}>
        <DefaultFooter content={footerRoutes} />
      </div>
    </>
  );
}

export default ContactUs;
