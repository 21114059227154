import React, { useEffect, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import Navbar from "react-bootstrap/Navbar";
import NavLink from "react-bootstrap/NavLink";
import "bootstrap/dist/css/bootstrap.min.css";
import Cx from "./page.module.scss";
import { LuSettings } from "react-icons/lu";
import { IoCalendarOutline } from "react-icons/io5";
import { IoClose } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/agfinancelogs/logoheader.svg";
import Box from "@mui/material/Box";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { fetchCalendlyData } from "../../redux/slice/getSlice";
import axios from "axios";
import PropTypes from "prop-types";
const Header = ({ activeClass }) => {
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loeData, setLoeData] = useState();
  const [linkData, setLinkData] = useState();
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const dispatch = useDispatch();
  const userType = localStorage.getItem("UserType");
  const userId = localStorage.getItem("userId");

  const fetchLoeEvents = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}loeapp/?user_id=${userId}`
      );

      if (response) {
        setLoeData(response.data);
      }
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    }
  };

  const onCalendarHandler = async () => {
    setLinkData();
    const apiMeetingData = await axios.get(`${process.env.REACT_APP_BASE_URL}calendly/`);
    const meetingData = apiMeetingData?.data;
    const discoveryCallLink = meetingData?.find((item) => item?.type === "discovery");
    const technicalCallLink = meetingData?.find((item) => item?.type === "technical");
    const linkData = loeData?.length > 0 ? technicalCallLink?.link : discoveryCallLink?.link;
    setLinkData(linkData);
  };

  useEffect(() => {
    dispatch(fetchCalendlyData());
    fetchLoeEvents();
    onCalendarHandler();
  }, [dispatch]);

  const handleLogout = async () => {
    setLoading(true);
    try {
      navigate("/");
      localStorage.removeItem("email");
      localStorage.removeItem("userId");
      localStorage.removeItem("UserType");
      window.location.reload();
    } catch (error) {
      console.error("Logout error:", error);
    } finally {
      setLoading(false);
      setShowModal(false);
    }
  };

  const taxLoeData = loeData && loeData?.filter((event) => event.role === "tax");
  return (
    <>
      <Navbar collapseOnSelect expand="lg" className={Cx.mainheader}>
        <div className="container-fluid">
          <Navbar.Brand href="/" className={Cx.logoimge}>
            <img src={Logo} alt="Logo" />
          </Navbar.Brand>
          <div>
            <div className={Cx.navpoints}>
              <NavLink
                href={linkData}
                className={Cx.navlink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <IoCalendarOutline />
              </NavLink>
              <NavLink onClick={handleShow} className={Cx.navlinksetting}>
                <LuSettings />
              </NavLink>
            </div>
          </div>
        </div>

        <Offcanvas show={show} onHide={handleClose} className="offcanvas-start">
          <Offcanvas.Title>
            <div className={Cx.accounts}>
              <Link className={Cx.backbtn}>
                <IoClose onClick={handleClose} />
              </Link>
            </div>
          </Offcanvas.Title>
          <Offcanvas.Body>
            <div>
              <ul>
                {userType === "is_business" ? (
                  <li>
                    <Link
                      to="/welcome-analysis"
                      className={
                        activeClass === "Welcome Call Questions"
                          ? `${Cx.backbtn2} ${Cx.backbtnactive}`
                          : Cx.backbtn2
                      }
                    >
                      Welcome Call Questions
                    </Link>
                  </li>
                ) : (
                  <>
                    {taxLoeData && taxLoeData.length <= 0 && (
                      <li>
                        <Link
                          to="/discovery-analysis"
                          className={
                            activeClass == "Discovery Call Questions"
                              ? `${Cx.backbtn2} ${Cx.backbtnactive}`
                              : Cx.backbtn2
                          }
                        >
                          Discovery Call Questions
                        </Link>
                      </li>
                    )}
                    {taxLoeData && taxLoeData.length > 0 && (
                      <Link
                        to="/project-list"
                        className={
                          activeClass == "Technical Call Questions"
                            ? `${Cx.backbtn2} ${Cx.backbtnactive}`
                            : Cx.backbtn2
                        }
                      >
                        Technical Call Questions
                      </Link>
                    )}
                  </>
                )}

                {userType === "is_business" ? (
                  <li>
                    <Link
                      to="/dashboard-business"
                      className={
                        activeClass == "dashboardbusiness"
                          ? `${Cx.backbtn2} ${Cx.backbtnactive}`
                          : Cx.backbtn2
                      }
                    >
                      Dashboard
                    </Link>
                  </li>
                ) : (
                  <li>
                    <Link
                      to="/dashboard"
                      className={
                        activeClass == "dashboard"
                          ? `${Cx.backbtn2} ${Cx.backbtnactive}`
                          : Cx.backbtn2
                      }
                    >
                      Dashboard
                    </Link>
                  </li>
                )}
                <li>
                  <Link
                    to="/application-status"
                    className={
                      activeClass == "ApplicationStatus"
                        ? `${Cx.backbtn2} ${Cx.backbtnactive}`
                        : Cx.backbtn2
                    }
                  >
                    Application Status
                  </Link>
                </li>
                <li>
                  <Link
                    to="/report-table"
                    className={
                      activeClass == "Report Table"
                        ? `${Cx.backbtn2} ${Cx.backbtnactive}`
                        : Cx.backbtn2
                    }
                  >
                    Report Table
                  </Link>
                </li>
                <li>
                  <p className={Cx.backbtn2}>
                    <Link
                      to={
                        "https://login.xero.com/identity/connect/authorize?response_type=code&client_id=AF64D25746694AFF9B97EC735E4C6A1E&redirect_uri=https%3A//web.agfinancehub.com/&scope=openid%20profile%20email%20offline_access%20accounting.transactions%20accounting.reports.read%20accounting.settings"
                      }
                      className={
                        activeClass == "SyncAccounting"
                          ? `${Cx.backbtn2} ${Cx.backbtnactive}`
                          : Cx.backbtn2
                      }
                      target="_blank"
                    >
                      Sync Accounting Tool
                    </Link>
                    <div className={Cx.iconlock}>
                      <FontAwesomeIcon icon={faLock} />
                    </div>
                  </p>
                </li>
                <li>
                  <Link to="/create-profile" className={Cx.backbtn2}>
                    My Profile
                  </Link>
                </li>
                {userType === "is_business" ? (
                  <></>
                ) : (
                  <li>
                    <Link to="/project-document" className={Cx.backbtn2}>
                      Supporting Document
                    </Link>
                  </li>
                )}
                <li>
                  <Link to="/change-password" className={Cx.backbtn2}>
                    Change Password
                  </Link>
                </li>
                <li>
                  <Link to="/terms-condition" className={Cx.backbtn2}>
                    Terms and Conditions
                  </Link>
                </li>
                <li>
                  <Link to="/privacy-policy" className={Cx.backbtn2}>
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link to="/lookingfor" className={Cx.backbtn2}>
                    Switch Profile
                  </Link>
                </li>
                {/* <li>
                  <Link to="/technical-analysis" className={Cx.backbtn2}>
                    Technical Analysis Questions
                  </Link>
                </li> */}
                <li>
                  <Link to="/delete-account" className={Cx.backbtn2}>
                    Delete Account
                  </Link>
                </li>
              </ul>
              <div className="mt-2">
                <button
                  type="button"
                  onClick={() => setShowModal(true)}
                  className={Cx.submitButton2}
                >
                  Logout
                </button>
              </div>
              <Modal
                size="md"
                show={showModal}
                className="modal fade"
                aria-hidden="true"
                onHide={() => setShowModal(false)}
                centered
              >
                <Box className={Cx.popuarea}>
                  <h4>Are you sure you want to logout?</h4>
                  <div className={Cx.modalActions}>
                    <button className={Cx.submitButton2} onClick={() => setShowModal(false)}>
                      No
                    </button>
                    <button className={Cx.submitButton2} onClick={handleLogout} disabled={loading}>
                      {loading ? "Logging out..." : "Yes"}
                    </button>
                  </div>
                </Box>
              </Modal>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </Navbar>
    </>
  );
};

export default Header;

Header.propTypes = {
  activeClass: PropTypes.string,
};
