import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Cx from "../../../style/custom.module.scss";
import DefaultFooter from "examples/Footers/DefaultFooter";
import footerRoutes from "footer.routes";
import Headerhome from "layouts/hearderhome/Headerhome";

/* import bgImage from "assets/images/bg-about-us.jpg";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import routes from "routes";
import MKButton from "components/MKButton"; */

const HMRCsRight = () => {
  return (
    <>
      {/* <DefaultNavbar
      routes={routes}
      action={{
        type: "external",
        route: "https://www.creative-tim.com/product/agfinance-hub-react",
        label: "free download",
        color: "default",
      }}
    /> */}
      <Headerhome />

      <MKBox
        width="100%"
        className="pt-5 pb-5"
        sx={{
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={12}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            className={Cx.contantabout}
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <div className={Cx.main_heading}>
              <MKTypography>
                <h1>Is HMRC’s view on R&D always right?</h1>
              </MKTypography>
              <MKTypography>
                <h2>AG FINANCE AND TECHNOLOGY GROUP LTD</h2>
              </MKTypography>
            </div>
          </Grid>
        </Container>
      </MKBox>
      <Card className="p-4 mx-5">
        <div className={Cx.abutotextarea}>
          <div className={Cx.heading_textarea}>
            <h2>HMRC&#39;s Chief Digital Information Officers Get It Wrong…sometimes.</h2>
            <p>
              Software Get Onbord Limited very recently won a landmark case against HMRC, something
              which gives hope to all company’s being wrongly rejected for their commitment to innovation
              within the permitted parameters of the UK R&D criteria. In this case, HMRC&#39;s Chief Digital
              Information Officers reviewed the project and concluded that no R&D had taken place, offering
              no detailed rationale for this view. Additionally, the HMRC witness lacked experience in
              software, handling his first such claim. The lack of experience and credentials of the HMRC
              caseworker and the digital team they consulted was highlighted, raising questions about the
              validity of their assessment.
            </p>
            <p>
              The central question revolved around whether the company’s operations complied with the HMRC&#39;s Research & Development criteria. The Tribunal concluded that Get Onbord’s activities indeed adhered to these standards.
            </p>
            <p>
              This ruling serves as a powerful illustration of the practicalities of software development and the application of existing technologies in R&D within the software industry. Previously, HMRC caseworkers have frequently used these factors to challenge and disqualify legitimate R&D projects. The decision underscores that using existing tools and open-source software does not automatically disqualify a project from R&D consideration, as long as the project shows significant innovation and goes beyond routine changes, tweaks, fixes.
            </p>
            <p>
              HMRC argued that Get Onbord Ltd’s (GOL) project did not advance overall knowledge or capability and therefore did not qualify as R&D. The project aimed to develop an innovative, automated AI process for know-your-client (KYC) verification and risk profiling, promising outcomes superior to human analysis and full compliance with regulatory requirements.
            </p>
            <p>
              Although HMRC acknowledged the technology as &#34;impressive,&#34; they questioned the advancement achieved, arguing that the project used existing technologies to create an innovative product, which did not meet R&D criteria because &#34;the routine analysis, copying, or adaptation of an existing product, process, service, or material, will not be an advance in science or technology.&#34;
            </p>
            <p>
              GOL presented evidence of weaknesses in existing technologies, demonstrating a clear technological gap and arguing that their advancements were not readily deducible.
            </p>
            <p>
              GOL&#39;s Mr. Cahill, a seasoned professional with over 25 years in the field, explained that the project generated new knowledge and capabilities using existing code. He emphasised, &#34;Every piece of code is built on existing code; nobody writes code from scratch. Why would you when someone else has already done the work? GOL works by taking components and adding to them. It is rare for software development to be completely novel.&#34;
            </p>
            <p>
              When making an R&D tax claim, it is the company&#39;s responsibility, not HMRC’s, to justify the credits claimed. However, once a claimant demonstrates a technological advance, the burden shifts to HMRC to provide evidence to the contrary. The Tribunal cited an example: &#34;We have done enough to raise the case that our project comprised an overall advance in science and technology. Over to HMRC to produce some material to show that, despite what appears from everything we have produced, our project was a routine advance&#34; (Para. 75–77 & 96).
            </p>
            <p>
              Despite lacking formal qualifications in programming and software development, Mr. Cahill fluently discussed the technical aspects of the R&D project, showcasing his coding experience and up-to-date industry knowledge. The Tribunal referenced Sam Altman, CEO of OpenAI, who, despite dropping out of Stanford’s computer science program, is regarded as a competent professional.
            </p>
            <p>
              The Tribunal clarified that the use of open-source or existing materials does not inherently indicate that a development is routine or readily discernible. Whether a development constitutes an overall advance depends on individual cases, specifically if it is a routine advance or readily discernible. It is not necessary for each component of the solution to be novel or bespoke to qualify as R&D. Given the prevalence of open-source software and AI materials, requiring complete novelty would disqualify virtually all software projects from R&D status.
            </p>

          </div>
        </div>
      </Card>

      <MKBox pt={6} px={0} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
};

export default HMRCsRight;
