// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
/* import GitHubIcon from "@mui/icons-material/GitHub";
import YouTubeIcon from "@mui/icons-material/YouTube"; */

// AG Finance React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/agfinancelogs/logoheader.svg";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "AG Finance Hub",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/CreativeTim/",
    },
    {
      icon: <TwitterIcon />,
      link: "https://twitter.com/creativetim",
    },
  ],
  menus: [
    {
      name: "company",
      items: [
        { name: "terms & condition", href: "/terms&condition" },
        { name: "privacy & policy", href: "/privacy&policy" },
      ],
    },

    {
      name: "help & support",
      items: [{ name: "contact & support", href: "/contact-support" }],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      <span style={{ color: "#fff" }}>
        All rights reserved. Copyright &copy; {date} AG Finance by and Technology Group LTD.
      </span>
    </MKTypography>
  ),
};
