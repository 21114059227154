import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Cx from "../../../style/custom.module.scss";
import DefaultFooter from "examples/Footers/DefaultFooter";
import footerRoutes from "footer.routes";
import Headerhome from "layouts/hearderhome/Headerhome";

/* import bgImage from "assets/images/bg-about-us.jpg";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import routes from "routes";
import MKButton from "components/MKButton"; */

const UKInflation = () => {
  return (
    <>
      {/* <DefaultNavbar
      routes={routes}
      action={{
        type: "external",
        route: "https://www.creative-tim.com/product/agfinance-hub-react",
        label: "free download",
        color: "default",
      }}
    /> */}
      <Headerhome />

      <MKBox
        width="100%"
        className="pt-5 pb-5"
        sx={{
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={12}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            className={Cx.contantabout}
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <div className={Cx.main_heading}>
              <MKTypography>
                <h1>UK inflation drops to 1.7% - increasing likelihood of further interest rate cuts as inflation dips below BoE target for the first time since 2021.</h1>
              </MKTypography>
              <MKTypography>
                <h2>AG FINANCE AND TECHNOLOGY GROUP LTD</h2>
              </MKTypography>
            </div>
          </Grid>
        </Container>
      </MKBox>
      <Card className="p-4 mx-5">
        <div className={Cx.abutotextarea}>
          <div className={Cx.heading_textarea}>
            <p>
              While a drop in interest rates benefits borrowers, it could be less favourable for savers as banks tend to reduce savings rates in line with cuts in the base rate. Additionally, this month’s inflation rate will likely inform the government’s decision on benefit increases for April, affecting payments such as jobseeker’s allowance, maternity allowance, housing benefit, and universal credit. Although pensions will see a 4.1% increase due to the triple-lock system, other benefits may rise by only 1.7%, pending confirmation in the upcoming budget.
            </p>
            <p>
              Experts expect inflation to edge up slightly next month due to rising energy costs, with analysts suggesting this may be the lowest point for CPI inflation this year. Higher oil prices and potential increases in duties from the October budget could push inflation higher again.
            </p>
            <p>
              Treasury Chief Secretary Darren Jones welcomed the news of lower inflation, calling it a positive development for millions of families, but emphasised the government&#39;s continued efforts to promote growth and economic stability.
            </p>
            <p>
              UK inflation has dropped to 1.7%, marking the first time it has fallen below the Bank of England’s 2% target since 2021. This represents a decrease from the 2.2% recorded in the previous month’s Consumer Prices Index (CPI), with analysts having predicted a smaller drop to 1.9%.
            </p>
            <p>
              The Bank of England (BoE) has been working to reduce inflation by maintaining higher interest rates. Recently, it lowered the base rate to 5%, and today&#39;s lower inflation figures increase the likelihood of further rate cuts, which would be positive news for mortgage holders. However, this reduction in inflation may lead to smaller-than-expected increases in benefits.
            </p>
            <p>
              It’s important to note that while inflation is decreasing, this doesn’t mean prices are dropping—only that they are rising at a slower pace. The 1.7% inflation rate, as reported by the Office for National Statistics (ONS), measures the average price increase of a basket of goods over the year to September 2024. The CPI does not factor in changes to mortgage interest payments or house prices.
            </p>
            <p>
              Inflation reached a peak of 11.1% in October 2022 due to soaring energy prices triggered by the Ukraine war. It has since dropped, reaching 2% earlier in the year before ticking back up. The last time inflation was below the government’s target was in April 2021 when it stood at 1.5%.
            </p>
            <p>
              The ONS attributes the recent inflation drop primarily to lower fuel and air fare costs. However, inflation on food and non-alcoholic beverages rose from 1.3% to 1.8%, marking the first increase since March 2023. Meanwhile, core inflation, which excludes volatile components like food and energy, fell from 3.6% to 3.2%.
            </p>
            <p>
              Looking ahead, mortgage holders may anticipate a likely cut in interest rates when the Bank of England meets on November 7. Experts predict a reduction from 5% to 4.75%. Another potential rate cut could follow in December, with the BoE meeting just before Christmas, and again in early February 2025.
            </p>
          </div>
        </div>
      </Card>

      <MKBox pt={6} px={0} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
};

export default UKInflation;
