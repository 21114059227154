import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Cx from "../../../style/custom.module.scss";
import DefaultFooter from "examples/Footers/DefaultFooter";
import footerRoutes from "footer.routes";
import Headerhome from "layouts/hearderhome/Headerhome";

function TermsCondition() {
  return (
    <>
      <Headerhome />
      <section className={`${Cx.section_about} position-relative`}>
        <div className="container-fluid mx-0 px-0">
          <div className="container m-auto">
            <div className={Cx.headerpart}>
              <div className="row m-auto">
                <h1>User Terms And Conditions</h1>
                <h2>AG Finance and Technology Group Ltd</h2>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className={Cx.abutotextarea}>
                <MKTypography variant="body1" className={Cx.heading_textarea}>
                  <h2>Introduction</h2>
                  <p>
                    Welcome to the AG FINANCE AND TECHNOLOGY GROUP LTD (“<b>AG Finance Hub</b>”)
                    Website and/or App. If you continue to browse or use this Website and/or App. or
                    mobile application you are agreeing to comply with and be bound by the following
                    terms and conditions of use (“<b>Terms</b>”) which govern our relationship with
                    you in relation to this Website and/or App. If you disagree with any part of
                    these Terms, please do not use our Website and/or App.
                  </p>
                </MKTypography>
                <MKTypography variant="body1" className={Cx.heading_textarea}>
                  <p>
                    The term <b>“we”</b> or <b>“us”</b> means AG FINANCE AND TECHNOLOGY GROUP LTD,
                    the legal owner of the mobile application AG Finance Hub, and website
                    www.agfinancehub.com, and operator of the website www.agfinancehub.com and the
                    mobile application ‘AG Finance Hub’ (the “Website and/or App.”), whose
                    registered office is 71-75 Shelton Street, Covent Garden, London, United
                    Kingdom, WC2H 9JQ and whose company registration number is 15901932 (“us” and
                    “our” will be construed accordingly). These user terms and conditions cover both
                    our website and mobile application, depending on which you are using at the
                    time.
                  </p>
                </MKTypography>
                <MKTypography variant="body1" className={Cx.heading_textarea}>
                  <p>
                    The term “you” refers to the user or viewer of our Website and/or App (and
                    “your” will be construed accordingly).
                  </p>
                  <p>
                    This Website and/or App uses cookies. By using this Website and/or App and
                    agreeing to these Terms, you consent to our use of cookies in accordance with
                    the terms of our Cookies Policy and to the use of your information in accordance
                    with our Privacy Policy.
                  </p>
                </MKTypography>
                <MKTypography variant="body1" className={Cx.heading_textarea} sx={{ mt: 10 }}>
                  <h3>Use of this Website and/or App.</h3>
                  <p>
                    You must be over 18 years of age to purchase or make use of any products or
                    services from our Website and/or App.
                  </p>
                </MKTypography>
                <MKTypography variant="body1" className={Cx.heading_textarea}>
                  <p>
                    Unless otherwise stated, AG Finance Hub (AG FINANCE AND TECHNOLOGY GROUP LTD)
                    owns all rights and the intellectual property rights in the Website and/or App
                    and material on the Website and/or App. Subject to the licence below, all these
                    intellectual property rights are reserved.
                  </p>

                  <p>
                    You may view Website and/or App pages, download Website and/or App pages, and
                    print Website and/or App pages, subject to the restrictions set out below and
                    elsewhere in these Terms.
                  </p>

                  <p>
                    You must not use our Website and/or App in any way that causes, or may cause,
                    damage to the Website and/or App or impairment of the availability or
                    accessibility of the Website and/or App or in any way which is unlawful,
                    illegal, fraudulent, or harmful, or in connection with any unlawful, illegal,
                    fraudulent, or harmful purpose or activity.
                  </p>

                  <p>
                    You must not use this Website and/or App to copy, store, host, transmit, send,
                    use, publish, or distribute any material which consists of malicious computer
                    software.
                  </p>

                  <p>
                    You must not use our Website and/or App to transmit or send unsolicited
                    commercial communications.
                  </p>

                  <p>
                    You must not use our Website and/or App for any purposes related to sales or
                    marketing of our content or materials without our express written consent.
                  </p>

                  <p>
                    Access to certain areas of this Website and/or App is restricted. We reserve the
                    right to restrict access to areas of this Website and/or App or indeed this
                    entire Website and/or App, at our sole discretion.
                  </p>

                  <p>
                    If we provide you with a user ID and password to enable you to access restricted
                    areas of this Website and/or App or other content or services, you must ensure
                    that the user ID and password are kept confidential.
                  </p>

                  <p>
                    We may disable your user ID and password at our sole discretion without prior
                    notice, if we believe you are making improper use of the Website and/or App.
                  </p>
                </MKTypography>
                <MKTypography variant="body1" className={Cx.heading_textarea} sx={{ mt: 5 }}>
                  <h3>Disclaimer</h3>

                  <p>
                    You understand that any legal, financial, or tax advice which may appear on the
                    Website and/or App has been designed to provide general application to common
                    situations only. It may not, and we make no warranty to you, cover any specific
                    situation which applies to you, and we give no warranty that any such advice
                    will be fit for your intended purpose. We recommend that you always take
                    specific legal, financial, or tax advice before relying on information you
                    obtain through the Website and/or App to ensure that it meets your requirements.
                  </p>

                  <p>
                    Due to the continuous update and change in HMRC policy and practice, we do not
                    warrant that any content available on the Website and/or App is always accurate
                    or up-to-date. You should always take specific legal, financial, or tax advice
                    before relying on information you obtain through the Website and/or App.
                  </p>

                  <p>
                    AG Finance Hub App is entirely owned by AG Finance and Technology Group Ltd,
                    founded by Aaron Gera and Aman Gera.
                  </p>

                  <p>
                    The maximum liability of AG Finance Hub, its owners, agents, employees,
                    contractors, and subcontractors to any person in respect of the purchase or use
                    of any products or services through the Website and/or App is limited to the
                    purchase price paid by the user for those services.
                  </p>

                  <p>
                    We are not a broker or lender but connect you to lending professionals who
                    provide you with the solution you need. All of our corporate tax services are
                    delivered with experienced and regulated chartered accountants and chartered tax
                    advisors. Your company accepts and agrees that we may share your documents and
                    details with our strategic partners, brokers, or providers of business finance
                    who work with us, in instances whereby your company requests services for
                    business finance solutions. The aforementioned will share this data with a panel
                    of lenders in order to provide you with any financing options, and where
                    required, terms under relevant contract from those lenders.
                  </p>

                  <p>
                    By using AG Finance Hub, you are agreeing to give consent for such companies to
                    use the data provided in your finance application for the purpose of bringing
                    you finance options. By proceeding to use AG Finance Hub, your company consents
                    for AG Finance and Technology Group Limited to provide this information where
                    required in order to bring you financing solutions as per your requirement.
                  </p>

                  <p>
                    Unless communicated otherwise via email, our company, strategic partners,
                    brokers, or providers of business finance solutions will use your data on the
                    basis that you agree for us to do so. If you require terms of use before the
                    business finance applications are made, please let us know via email, and we
                    will happily do so. It is agreed that the data provided in your application will
                    not be disclosed to any third party for any other purpose than to provide you
                    with business finance options. If you do not give consent to us or our strategic
                    partners, brokers, or providers of business finance who work with us, please do
                    not use AG Finance Hub.
                  </p>

                  <p>
                    We are in no way responsible for any financial decisions made by your company,
                    and we strongly recommend that you seek specific and independent legal,
                    financial, and tax advice at all times.
                  </p>
                </MKTypography>
                <MKTypography variant="body1" className={Cx.heading_textarea} sx={{ mt: 5 }}>
                  <h3>Copyright and Licence</h3>

                  <p>
                    This Website and/or App contains material which is owned by or licensed to us.
                    This material includes, but is not limited to, the content, design, layout,
                    look, appearance, and graphics, and all of the material available whether for
                    free or for purchase through the Website and/or App.
                  </p>

                  <p>
                    All material contained in this Website and/or App is and shall remain at all
                    times the copyright of AG Finance and Technology Group Ltd.
                  </p>

                  <p>
                    The AG Finance and Technology Group logo, AG Finance Hub logo, and the web
                    application AG Finance Hub are all protected by Copyright, registered, and
                    certified by protectmywork.com.
                  </p>

                  <p>
                    You must retain, and must not delete or remove, all copyright notices and other
                    proprietary notices placed by us on any material.
                  </p>
                </MKTypography>
                <MKTypography variant="body1" className={Cx.heading_textarea} sx={{ mt: 5 }}>
                  <h3>User Content</h3>

                  <p>
                    The Website and/or App may in the future provide comment or discussion forums
                    which allow the submission of text, images, videos, or other content by you and
                    other users (&quot;User Content&quot;) and the hosting and publishing of such
                    User Content. You understand that whether or not such User Content is published,
                    we do not guarantee any confidentiality with respect to any User Content.
                  </p>

                  <p>
                    You shall be solely responsible for your own User Content and the consequences
                    of posting or publishing them.
                  </p>

                  <p>
                    <b>You represent and warrant that:</b>
                  </p>

                  <ul>
                    <li>
                      You own or have the necessary rights and permissions to use and authorize us
                      to use all copyright, trademark, or other proprietary rights in and to any
                      User Content to enable inclusion and use on the Website and/or App, and in
                      accordance with these Terms.
                    </li>

                    <li>
                      Whilst you retain all of your ownership rights in your User Content, by
                      submitting the User Content to us, you hereby grant us, in addition to any
                      other rights which we may have, a worldwide, non-exclusive, and transferable
                      license to use, copy, prepare derivative works of, display, and broadcast the
                      User Content in connection with the Website and/or App and our business,
                      including without limitation to grant access to the Website and/or App to
                      third parties to view the User Content (and derivative works thereof).
                    </li>
                  </ul>

                  <h3>You will not:</h3>

                  <ul>
                    <li>
                      Submit material that is false or misleading, copyrighted, protected by trade
                      secret, or otherwise subject to third-party proprietary rights, including
                      privacy and publicity rights, unless you are the owner of such rights or have
                      permission from their rightful owner to post the User Content and to grant us
                      all of the license rights granted herein.
                    </li>

                    <li>
                      Publish falsehoods or misrepresentations that could damage us, our business,
                      or any third party.
                    </li>

                    <li>
                      Submit material that is unlawful, obscene, libelous, threatening,
                      pornographic, or encourages conduct that would be considered a criminal
                      offense, give rise to civil liability, or violate any law.
                    </li>

                    <li>
                      Misidentify yourself in submitting the User Content or misstate your true
                      identity.
                    </li>
                  </ul>

                  <p>
                    Any breach of the above warranties will result in the user’s account being
                    immediately terminated and may result in the user becoming liable to legal
                    action.
                  </p>

                  <p>
                    We do not endorse any User Content or any opinion, recommendation, or advice
                    expressed therein, and we expressly disclaim any and all liability in connection
                    with User Content. You understand that when using the Website and/or App, you
                    will be exposed to User Content from a variety of sources, and that we are not
                    responsible for the accuracy, usefulness, safety, or intellectual property
                    rights of or relating to such User Content. You may be exposed to User Content
                    that is inaccurate, offensive, indecent, or objectionable, and you agree to
                    waive, and hereby do waive, any legal or equitable rights or remedies you may
                    have against us with respect thereto, and agree to indemnify and hold us, our
                    owners, affiliates, employees, agents, and/or licensors, harmless to the fullest
                    extent allowed by law regarding all matters related to your use of the Website
                    and/or App.
                  </p>
                </MKTypography>
                <MKTypography variant="body1" className={Cx.heading_textarea} sx={{ mt: 5 }}>
                  <h3>No Warranties</h3>

                  <p>
                    This Website and/or App is provided “as is” without any representations or
                    warranties, express or implied. We make no representations or warranties in
                    relation to this Website and/or App or the information and material provided on
                    this Website and/or App.
                  </p>

                  <p>
                    We do not warrant that this Website and/or App will be constantly available, or
                    available at all; or that the information on this Website and/or App is
                    complete, true, accurate, or non-misleading.
                  </p>

                  <p>
                    Nothing on this Website and/or App constitutes, or is meant to constitute,
                    advice of any kind. We do not provide any warranty as to the suitability of the
                    information and material found or offered on this Website and/or App for any
                    particular purpose. Your use of any information or material on this Website
                    and/or App is entirely at your own risk, for which we shall not be liable. It
                    shall be your own responsibility to ensure that any materials, services, or
                    information available through this Website and/or App meet your own specific
                    requirements.
                  </p>

                  <p>
                    You acknowledge that information and material found or offered on this Website
                    and/or App may contain inaccuracies or errors, and we expressly exclude
                    liability for any such inaccuracies or errors to the fullest extent permitted by
                    law.
                  </p>

                  <p>
                    To the maximum extent permitted by applicable law, we exclude all
                    representations, warranties, and conditions relating to this Website and/or App
                    and the use of this Website and/or App (including, without limitation, any
                    warranties implied by law of satisfactory quality, fitness for purpose, and/or
                    the use of reasonable care and skill).
                  </p>
                </MKTypography>
                <MKTypography variant="body1" className={Cx.heading_textarea} sx={{ mt: 5 }}>
                  <h3>Limitations and Exclusions of Liability</h3>

                  <p>
                    To the extent that the Website and/or App and the information and services on
                    the Website and/or App are provided, our liability to you in relation to the use
                    of our Website and/or App or under or in connection with these Terms, whether in
                    contract, tort (including negligence) or otherwise, will be limited as follows:
                    AG Finance Hub and its employees, agents, and contractors will not be liable to
                    you for any loss or damage of any nature whether arising directly or indirectly
                    from the use of or reliance on information obtained from this Website and/or
                    App; AG Finance Hub and its employees, agents, and contractors will not be
                    liable for any consequential, indirect, or special loss or damage and will not
                    be liable for any loss of profit, income, revenue, anticipated savings,
                    contracts, business, goodwill, reputation, data, or information.
                  </p>

                  <p>
                    Nothing in these Terms will limit or exclude our liability for death or personal
                    injury resulting from negligence, limit or exclude our liability for fraud or
                    fraudulent misrepresentation, or limit any of our liabilities in any way that is
                    not permitted under applicable law.
                  </p>

                  <p>
                    By using this Website and/or App, you agree that the exclusions and limitations
                    of liability set out in these Terms are reasonable. If you do not think they are
                    reasonable, you must not use this Website and/or App.
                  </p>
                </MKTypography>
                <MKTypography variant="body1" className={Cx.heading_textarea} sx={{ mt: 5 }}>
                  <h3>Indemnity</h3>

                  <p>
                    If you breach these Terms, you will be held fully responsible for any loss
                    suffered by us as a result of such breach and will be held accountable for all
                    losses caused or profits gained by you from breaching these Terms.
                  </p>

                  <p>
                    You agree to indemnify us and undertake to keep us indemnified against any
                    losses, damages, costs, liabilities, and expenses (including, without
                    limitation, legal expenses) incurred or suffered by us arising out of any breach
                    by you of any provision of these Terms.
                  </p>
                </MKTypography>

                <MKTypography variant="body1" className={Cx.heading_textarea} sx={{ mt: 5 }}>
                  <h3>Other Website and/or Apps</h3>

                  <p>
                    This Website and/or App may contain links to other Website and/or App’s that are
                    not under the control of and are not maintained by us. We are not responsible
                    for the content or reliability of the linked Website and/or App’s. We provide
                    these links for your convenience only but do not endorse the material on those
                    sites.
                  </p>

                  <h3>Waiver</h3>

                  <p>
                    The failure by us to enforce at any time or for any period any one or more of
                    the Terms shall not be a waiver of them or the right at any time subsequently to
                    enforce all Terms.
                  </p>

                  <h3>Severance</h3>

                  <p>
                    If any provision of these Terms shall be found by any court to be invalid or
                    unenforceable, such invalidity or unenforceability shall not affect the other
                    provisions of these Terms which shall remain in full force and effect.
                  </p>

                  <p>
                    If any provision of these Terms is so found to be invalid or unenforceable but
                    would be valid or enforceable if some part of the provision were deleted, the
                    provision in question shall apply with such modification(s) as may be necessary
                    to make it valid and enforceable.
                  </p>

                  <h3>Variation</h3>

                  <p>
                    We may revise these Terms from time-to-time. Revised Terms will apply to the use
                    of our Website and/or App from the date of the publication of the revised Terms
                    on our Website and/or App. Please check this page regularly to ensure you are
                    familiar with the current version.
                  </p>

                  <h3>Exclusion of Third Party Rights</h3>

                  <p>
                    These Terms are for the benefit of you and us, and are not intended to benefit
                    any third party or be enforceable by any third party. The exercise of our and
                    your rights in relation to these Terms is not subject to the consent of any
                    third party.
                  </p>

                  <h3>Entire Agreement</h3>

                  <p>
                    These Terms constitute the entire agreement between you and us in relation to
                    your use of our Website and/or App, and supersede all previous agreements in
                    respect of your use of this Website and/or App.
                  </p>

                  <h3>Jurisdiction and Governing Law</h3>

                  <p>
                    These Terms shall be governed by and construed in accordance with English law.
                    Any dispute, claim or matter arising out of, or relating to, these Terms shall
                    be subject to the exclusive jurisdiction of the English courts.
                  </p>
                </MKTypography>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid px-0 mx-0 ">
          <div className={Cx.bannertopimage1}>
            <div className={Cx.paddingarea1}></div>
          </div>
        </div>
      </section>

      <MKBox px={0}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default TermsCondition;
