import { useEffect } from "react";
import "./App.css";
import { Routes, Route, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "assets/theme";
import Home from "layouts/pages/Home";
import routes from "routes";
import Deleteaccount from "pages/LandingPages/Deleteaccount";
import ApplicationStatus from "pages/LandingPages/applicationstatus/ApplicationStatus";
import ChangePassword from "pages/LandingPages/changepassword/ChangePassword";
import CreateProfile from "pages/LandingPages/createprofile/CreateProfile";
import Dashboard from "pages/LandingPages/dashboard/Dashboard";
import ForgetPassword from "pages/LandingPages/forgetpassword/ForgetPassword";
import LookingFor from "pages/LandingPages/lookingfor/LookingFor";
import NotFound from "pages/LandingPages/notfound/NotFound";
import OtpVerification from "pages/LandingPages/otpverification/Otpverification";
import ResetPassword from "pages/LandingPages/resetpassword/ResetPassword";
import TechnicalAnalysis from "pages/LandingPages/technicalanalysis/TechnicalAnalysis";
import UploadDocument from "pages/LandingPages/uploaddocument/UploadDocument";
import TermsCondition from "pages/LandingPages/TermsConditions";
import PrivacyPolicy from "pages/LandingPages/PrivacyPolicy";
import CommonToaster from "../src/utils/Toster";
import DiscoveryAnalysis from "pages/LandingPages/discoveryquestion/DiscoveryAnalysis";
import WelcomeQuestion from "pages/LandingPages/WelcomeQuestion/welcomequestion";
import QuestionInstructionScreen from "pages/LandingPages/Instructions/Instructions";
import ProjectDocumentUpload from "pages/LandingPages/ProjectDocumentUpload/ProjectDocumentUpload";
import UploadBusinessDocument from "pages/LandingPages/uploadbusinessdocument/UploadBusinessDocument";
import DashboardBusiness from "pages/LandingPages/dashboardbusiness/DashboardBusiness";
import ProtectedRoute from "utils/ProtectedRoute";
import PublicRoute from "utils/PublicRoute";
import DiscoveryAnalysisAdd from "pages/LandingPages/discoveryquestionadd/DiscoveryAnalysisAdd";
import ProjectList from "pages/LandingPages/projectList/ProjectList";
import ProjectQustions from "pages/LandingPages/projectqustions/ProjectQustions";
import TechnicalAnalysisEdit from "pages/LandingPages/technicalanalysisedit/TechnicalAnalysisEdit";
import About from "pages/LandingPages/About";
import Blogs from "pages/LandingPages/Blogone";
import ReportTable from "pages/LandingPages/reporttable/ReportTable";

import Signuplogin from "pages/LandingPages/Signuplogin/Signuplogin";
import SignUp from "pages/LandingPages/register/SignUp";
import DeleteAccountUser from "./pages/LandingPages/DeleteUser";
import Table from "pages/LandingPages/tabletest/table";
import PasswordPage from "pages/LandingPages/PasswordPage/PasswordPage";

import ResearchDevelopment from "pages/LandingPages/BlogDetails/ResearchDevelopment";
import AutumnBudget from "pages/LandingPages/BlogDetails/AutumnBudget";
import CorporateReconstructions from "pages/LandingPages/BlogDetails/CorporateReconstructions";
import UKInflation from "pages/LandingPages/BlogDetails/UKInflation";
import HMRCsRight from "pages/LandingPages/BlogDetails/HMRCsRight";

export default function App() {
  const { pathname } = useLocation();
  const id = localStorage.getItem("userId");
  const uniquelogin = localStorage.getItem("uniquelogin");

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes?.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      if (route.route) {
        return <Route path={route.route} element={route.component} key={route.key} />;
      }
      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        {uniquelogin ? (
          <>
            {getRoutes(routes)}
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/terms-condition" element={<TermsCondition />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/about" element={<About />} />
            <Route path="/blogone" element={<Blogs />} />
            <Route path="/delete-account" element={<Deleteaccount />} />
            <Route element={<PublicRoute isAuthenticated={!id} />}>
              <Route path="/login" element={<Signuplogin />} />
              <Route path="/signUp" element={<SignUp />} />
              <Route path="/forget-password" element={<ForgetPassword />} />
              <Route path="/otp-verification" element={<OtpVerification />} />
              <Route path="/reset-password" element={<ResetPassword />} />
            </Route>
            <Route element={<ProtectedRoute isAuthenticated={!!id} />}>
              <Route path="/lookingfor" element={<LookingFor />} />
              <Route path="/upload-document" element={<UploadDocument />} />
              <Route path="/upload-business-document" element={<UploadBusinessDocument />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/dashboard-business" element={<DashboardBusiness />} />
              <Route path="/application-status" element={<ApplicationStatus />} />
              <Route path="/change-password" element={<ChangePassword />} />
              <Route path="/create-profile" element={<CreateProfile />} />
              <Route path="/technical-analysis" element={<TechnicalAnalysis />} />
              <Route path="/technical-analysis-edit/:id" element={<TechnicalAnalysisEdit />} />
              <Route path="/discovery-analysis" element={<DiscoveryAnalysis />} />
              <Route path="/discovery-analysis-add" element={<DiscoveryAnalysisAdd />} />
              <Route path="/welcome-analysis" element={<WelcomeQuestion />} />
              <Route path="/instruction" element={<QuestionInstructionScreen />} />
              <Route path="/project-document" element={<ProjectDocumentUpload />} />
              <Route path="/project-list" element={<ProjectList />} />
              <Route path="/project-qustions" element={<ProjectQustions />} />
              <Route path="/report-table" element={<ReportTable />} />
              <Route path="/delete-user-account" element={<DeleteAccountUser />} />
              <Route path="/Table" element={<Table />} />
            </Route>
            <Route path="/research-development" element={<ResearchDevelopment />} />
            <Route path="/autumn-budget" element={<AutumnBudget />} />
            <Route path="/corporate-reconstructions" element={<CorporateReconstructions />} />
            <Route path="/uk-inflation" element={<UKInflation />} />
            <Route path="/hmrc-right" element={<HMRCsRight />} />
            <Route path="*" element={<NotFound />} />
          </>
        ) : (
          <Route path="/" element={<PasswordPage />} />
        )}
      </Routes>

      <CommonToaster position="top-right" autoClose={2000} />
    </ThemeProvider>
  );
}
