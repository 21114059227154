import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Cx from "./page.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { fetchCalendlyData } from "../../redux/slice/getSlice";
import axios from "axios";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";

const Sidebar = ({ activeClass }) => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loeData, setLoeData] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userType = localStorage.getItem("UserType");
  const userId = localStorage.getItem("userId");

  const fetchLoeEvents = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}loeapp/?user_id=${userId}`
      );

      if (response) {
        setLoeData(response.data);
      }
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    }
  };

  useEffect(() => {
    dispatch(fetchCalendlyData());
    fetchLoeEvents();
  }, [dispatch]);

  const handleLogout = async () => {
    setLoading(true);
    try {
      navigate("/", { replace: true });
      localStorage.removeItem("email");
      localStorage.removeItem("userId");
      localStorage.removeItem("UserType");
      localStorage.removeItem("answerData");
      localStorage.removeItem("token");
      localStorage.removeItem("uniquelogin");
      window.location.reload();
    } catch (error) {
      console.error("Logout error:", error);
    } finally {
      setLoading(false);
      setShowModal(false);
    }
  };

  const taxLoeData = loeData && loeData?.filter((event) => event.role === "tax");

  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://api.agfinancehub.com/api/XeroList/?user_id=${userId}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [userId]);

  return (
    <>
      <div className={Cx.sidebar}>
        <ul>
          {userType === "is_business" ? (
            <li>
              <Link
                to="/welcome-analysis"
                className={
                  activeClass === "Welcome Call Questions"
                    ? `${Cx.backbtn2} ${Cx.backbtnactive}`
                    : Cx.backbtn2
                }
              >
                Welcome Call Questions
              </Link>
            </li>
          ) : (
            <>
              {taxLoeData && taxLoeData.length <= 0 && (
                <li>
                  <Link
                    to="/discovery-analysis"
                    className={
                      activeClass == "Discovery Call Questions"
                        ? `${Cx.backbtn2} ${Cx.backbtnactive}`
                        : Cx.backbtn2
                    }
                  >
                    Discovery Call Questions
                  </Link>
                </li>
              )}
              {taxLoeData && taxLoeData.length > 0 && (
                <li>
                  <Link
                    to="/project-list"
                    className={
                      activeClass == "Technical Call Questions"
                        ? `${Cx.backbtn2} ${Cx.backbtnactive}`
                        : Cx.backbtn2
                    }
                  >
                    Technical Call Questions
                  </Link>
                </li>
              )}
            </>
          )}

          {userType === "is_business" ? (
            <li>
              <Link
                to="/dashboard-business"
                className={
                  activeClass == "dashboardbusiness"
                    ? `${Cx.backbtn2} ${Cx.backbtnactive}`
                    : Cx.backbtn2
                }
              >
                Dashboard
              </Link>
            </li>
          ) : (
            <li>
              <Link
                to="/dashboard"
                className={
                  activeClass == "dashboard" ? `${Cx.backbtn2} ${Cx.backbtnactive}` : Cx.backbtn2
                }
              >
                Dashboard
              </Link>
            </li>
          )}
          <li>
            <Link
              to="/application-status"
              className={
                activeClass == "ApplicationStatus"
                  ? `${Cx.backbtn2} ${Cx.backbtnactive}`
                  : Cx.backbtn2
              }
            >
              Application Status
            </Link>
          </li>

          <li>
            <p className={Cx.backbtn2}>
              <Link
                to={
                  data
                    ? "/report-table"
                    : "https://login.xero.com/identity/connect/authorize?response_type=code&client_id=AF64D25746694AFF9B97EC735E4C6A1E&redirect_uri=https%3A//web.agfinancehub.com/&scope=openid%20profile%20email%20offline_access%20accounting.transactions%20accounting.reports.read%20accounting.settings"
                }
                className={
                  activeClass == "SyncAccounting"
                    ? `${Cx.backbtn2} ${Cx.backbtnactive}`
                    : Cx.backbtn2
                }
                target={data ? "_self" : "_blank"}
                rel={!data ? "noopener noreferrer" : undefined}
              >
                Sync Accounting Tool
              </Link>
              <div className={Cx.iconlock}></div> <FontAwesomeIcon icon={faLock} />
            </p>
          </li>
          <li>
            <Link to="/create-profile" className={Cx.backbtn2}>
              My Profile
            </Link>
          </li>
          {userType !== "is_business" && (
            <li>
              <Link
                to="/project-document"
                className={
                  activeClass == "SupportingDocument"
                    ? `${Cx.backbtn2} ${Cx.backbtnactive}`
                    : Cx.backbtn2
                }
              >
                Supporting Document
              </Link>
            </li>
          )}

          <li>
            <Link to="/change-password" className={Cx.backbtn2}>
              Change Password
            </Link>
          </li>
          <li>
            <Link to="/terms-condition" className={Cx.backbtn2}>
              Terms and Conditions
            </Link>
          </li>
          <li>
            <Link to="/privacy-policy" className={Cx.backbtn2}>
              Privacy Policy
            </Link>
          </li>
          <li>
            <Link to="/lookingfor" className={Cx.backbtn2}>
              Switch Profile
            </Link>
          </li>
          <li>
            <Link to="/delete-user-account" className={Cx.backbtn2}>
              Delete Account
            </Link>
          </li>
        </ul>
        <div className="mt-2">
          <button type="button" onClick={() => setShowModal(true)} className={Cx.submitButton2}>
            Logout
          </button>
        </div>
        <Modal
          size="md"
          show={showModal}
          className="modal fade"
          aria-hidden="true"
          onHide={() => setShowModal(false)}
          centered
        >
          <div className={Cx.popuarea}>
            <h4>Are you sure you want to logout?</h4>
            <div className={Cx.modalActions}>
              <button className={Cx.submitButton2} onClick={() => setShowModal(false)}>
                No
              </button>
              <button className={Cx.submitButton2} onClick={handleLogout} disabled={loading}>
                {loading ? "Logging out..." : "Yes"}
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Sidebar;

Sidebar.propTypes = {
  activeClass: PropTypes.string,
};
