import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./table.scss";

export default function Table() {
  const [reportData, setReportData] = useState();
  const userId = localStorage.getItem("userId");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://api.agfinancehub.com/api/XeroList/?user_id=${userId}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const result = await response.json();
        const profitAndLossReports = (result && result[0]?.data?.profit_and_loss) || [];
        setReportData(profitAndLossReports);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [userId]);

  console.log(reportData, "reportData");

  return (
    <section>
      <Container>
        <Row>
          <Col>
            <div className="profit-loss-container">
              <div className="section">
                <div className="row section-header">Turnover</div>
                <div className="row">
                  <span>Sales</span>
                  <span>{reportData?.Income?.sales}</span>
                </div>
                <div className="row bold">
                  <span>Total Turnover</span>
                  <span>{reportData?.Income?.totalincome}</span>
                </div>
              </div>

              <div className="section">
                <div className="row section-header">Cost of Sales</div>
                <div className="row">
                  <span>Subcontractor</span>
                  <span>{reportData?.CostOfSales?.subcontractor}</span>
                </div>
                <div className="row bold">
                  <span>Total Cost of Sales</span>
                  <span>{reportData?.CostOfSales?.totalcostofsales}</span>
                </div>
                <div className="row bold">
                  <span>Gross Profit</span>
                  <span>{reportData?.GrossProfit?.grossprofit}</span>
                </div>
              </div>

              <div className="section">
                <div className="row section-header">Administrative Costs</div>
                <div className="row">
                  <span>Admin Expense</span>
                  <span>{reportData?.OperatingExpenses?.adminexpense}</span>
                </div>
                {reportData &&
                  reportData?.OperatingExpenses &&
                  reportData?.OperatingExpenses["advertising&marketing"] && (
                    <div className="row">
                      <span>Advertising & Marketing</span>
                      <span>{reportData?.OperatingExpenses["advertising&marketing"]}</span>
                    </div>
                  )}

                <div className="row">
                  <span>Audit & Accountancy fees</span>
                  <span>
                    {reportData &&
                      reportData?.OperatingExpenses &&
                      reportData?.OperatingExpenses["audit&accountancyfees"]}
                  </span>
                </div>
                <div className="row">
                  <span>Bank fees</span>
                  <span>{reportData?.OperatingExpenses?.bankfees}</span>
                </div>
                <div className="row">
                  <span>Charitable And Political Donation</span>
                  <span>{reportData?.OperatingExpenses?.charitableandpoliticaldonations}</span>
                </div>
                <div className="row">
                  <span>Insurance</span>
                  <span>{reportData?.OperatingExpenses?.insurance}</span>
                </div>
                <div className="row">
                  <span>IT Software and Consumables</span>
                  <span>{reportData?.OperatingExpenses?.itsoftwareandconsumables}</span>
                </div>
                <div className="row">
                  <span>Legal Expenses</span>
                  <span>{reportData?.OperatingExpenses?.legalexpenses}</span>
                </div>

                <div className="row">
                  <span>Meeting Expense</span>
                  <span>{reportData?.OperatingExpenses?.meetingexpense}</span>
                </div>
                <div className="row">
                  <span>Membership</span>
                  <span>{reportData?.OperatingExpenses?.membership}</span>
                </div>
                <div className="row">
                  <span>Networking</span>
                  <span>{reportData?.OperatingExpenses?.networking}</span>
                </div>
                <div className="row">
                  <span>Office Equipment Expense</span>
                  <span>{reportData?.OperatingExpenses?.officeequipmentexpense}</span>
                </div>
                <div className="row">
                  <span>Office Expense</span>
                  <span>{reportData?.OperatingExpenses?.officeexpense}</span>
                </div>
                <div className="row">
                  <span>Postage, Freight & Courier</span>
                  <span>
                    {reportData &&
                      reportData?.OperatingExpenses &&
                      reportData?.OperatingExpenses["postage,freight&courier"]}
                  </span>
                </div>
                <div className="row">
                  <span>Printing & Stationery</span>
                  <span>
                    {reportData &&
                      reportData?.OperatingExpenses &&
                      reportData?.OperatingExpenses["printing&stationery"]}
                  </span>
                </div>
                <div className="row">
                  <span>Professional Expenses</span>
                  <span>{reportData?.OperatingExpenses?.professionalexpense}</span>
                </div>
                <div className="row">
                  <span>Rent</span>
                  <span>{reportData?.OperatingExpenses?.rent}</span>
                </div>
                <div className="row">
                  <span>Salaries</span>
                  <span>{reportData?.OperatingExpenses?.salaries}</span>
                </div>
                <div className="row">
                  <span>Subscriptions</span>
                  <span>{reportData?.OperatingExpenses?.subscriptions}</span>
                </div>
                <div className="row">
                  <span>Subsistence</span>
                  <span>{reportData?.OperatingExpenses?.subsistence}</span>
                </div>
                <div className="row">
                  <span>Telephone & Internet</span>
                  <span>
                    {reportData &&
                      reportData?.OperatingExpenses &&
                      reportData?.OperatingExpenses["telephone&internet"]}
                  </span>
                </div>
                <div className="row">
                  <span>Travel - National</span>
                  <span>
                    {reportData &&
                      reportData?.OperatingExpenses &&
                      reportData?.OperatingExpenses["travel-national"]}
                  </span>
                </div>

                <div className="row bold">
                  <span>Total Administrative Costs</span>
                  <span>{reportData?.OperatingExpenses?.totaloperatingexpenses}</span>
                </div>
              </div>

              <div className="section">
                <div className="row bold">
                  <span>Operating Profit</span>

                  <span>
                    {reportData?.AdminExpenses?.netprofit < 0
                      ? `(${Math.abs(reportData?.AdminExpenses?.netprofit).toLocaleString()})`
                      : reportData?.AdminExpenses?.netprofit.toLocaleString()}
                  </span>
                </div>
                <div className="row bold">
                  <span>Profit on Ordinary Activities Before Taxation</span>
                  <span>
                    {reportData?.AdminExpenses?.netprofit < 0
                      ? `(${Math.abs(reportData?.AdminExpenses?.netprofit).toLocaleString()})`
                      : reportData?.AdminExpenses?.netprofit.toLocaleString()}
                  </span>
                </div>
                <div className="row bold">
                  <span>Profit After Taxation</span>
                  <span>
                    {reportData?.AdminExpenses?.netprofit < 0
                      ? `(${Math.abs(reportData?.AdminExpenses?.netprofit).toLocaleString()})`
                      : reportData?.AdminExpenses?.netprofit.toLocaleString()}
                  </span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
