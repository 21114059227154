import React, { useState } from "react";
import Cx from "../../../style/custom.module.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { Box, Modal } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IoMailOutline } from "react-icons/io5";
//import { IoArrowBackOutline } from "react-icons/io5";

const DeleteAccountUser = () => {
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({ email: "" });
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  // Form validation logic
  const validateForm = () => {
    const newErrors = {};
    if (!email) newErrors.email = "Email is required.";
    else if (!/\S+@\S+\.\S+/.test(email)) newErrors.email = "Email is invalid.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    try {
      setLoading(true);

      const response = await axios.post(
        "https://api.agfinancehub.com/api/deleteuserprofile/",
        {
          email, // Replace `email` with the actual email value
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        console.log("API response:", response.data);
        navigate("/home");
        localStorage.removeItem("email");
        localStorage.removeItem("userId");
        localStorage.removeItem("UserType");
        localStorage.removeItem("token");
        window.location.reload();
      }
    } catch (error) {
      console.error("API error:", error.response?.data || error.message);
    } finally {
      setLoading(false);
      setShowModal(false);
    }
  };

  return (
    <>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-7 m-auto">
              <div className={Cx.signupage}>
                <div className={Cx.signtop}>
                  <div className={Cx.introText}>
                    <h2>Delete Account</h2>
                  </div>
                </div>
                <form className={Cx.signupForm}>
                  <div className={Cx.formGroup}>
                    <div className={Cx.inputGroup}>
                      <div className={Cx.icon}>
                        <IoMailOutline />
                      </div>

                      <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email"
                        required
                      />
                    </div>
                    {errors.email && <p className={Cx.errorMessage}>{errors.email}</p>}
                  </div>

                  {/* Submit Button */}
                  <div className="mt-5 text-center">
                    <button
                      type="button"
                      className={Cx.submitButton2}
                      disabled={loading}
                      onClick={() => setShowModal(true)} // Open modal on click
                    >
                      {loading ? "Deleting..." : "Delete Account"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Confirmation Modal */}
      <Modal
        size="lg"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        centered
        open={showModal}
        onClose={() => setShowModal(false)}
      >
        <Box className={Cx.popuarea}>
          <h4>Are you sure you want to delete your account?</h4>
          <div className={Cx.modalActions}>
            <button className={Cx.submitButton2} onClick={() => setShowModal(false)}>
              No
            </button>

            <button className={Cx.submitButton2} onClick={handleSubmit} disabled={loading}>
              Yes
            </button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default DeleteAccountUser;
