import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Cx from "./page.module.scss";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function DefaultFooter({ content }) {
  const { brand, socials, menus, copyright } = content;

  return (
    <>
      <MKBox component="footer" bgColor="white">
        <Container>
          <Grid
            container
            spacing={0}
            sx={{ mb: "0px", mt: "0px", m: "auto", pt: "30px", pb: "20px" }}
          >
            <Grid item xs={12} md={5} sx={{ mb: 3 }}>
              <MKBox>
                <Link to={brand.route}>
                  <MKBox
                    component="img"
                    src={brand.image}
                    alt={brand.name}
                    className={Cx.footerlogo}
                    mb={2}
                  />
                </Link>
                {/* <div className={Cx.brandname}>
                  <h2>{brand.name}</h2>
                </div> */}
              </MKBox>
              <MKBox display="flex" alignItems="center" mt={0} px={1}>
                {socials.map(({ icon, link }, key) => (
                  <MKTypography
                    key={link}
                    component="a"
                    href={link}
                    target="_blank"
                    className={Cx.iconsarea}
                    rel="noreferrer"
                    mr={key === socials.length - 1 ? 0 : 2.5}
                  >
                    <div className={Cx.iconpart}> {icon}</div>
                  </MKTypography>
                ))}
              </MKBox>
            </Grid>
            {menus.map(({ name: title, items }) => (
              <Grid key={title} item xs={12} md={3} className={Cx.footeritems}>
                <MKTypography
                  display="block"
                  variant="button"
                  fontWeight="bold"
                  textTransform="capitalize"
                  mb={1}
                  className={Cx.titlefooter}
                >
                  {title}
                </MKTypography>
                <MKBox component="ul" p={0} m={0} sx={{ listStyle: "none" }}>
                  {items.map(({ name, route, href }) => (
                    <MKBox key={name} component="li" p={0} m={0} lineHeight={1.25}>
                      {href ? (
                        <MKTypography
                          component="a"
                          className={Cx.namesfooter}
                          href={href}
                          //target="_blank"
                          rel="noreferrer"
                          variant="button"
                          fontWeight="regular"
                          textTransform="capitalize"
                        >
                          {name}
                        </MKTypography>
                      ) : (
                        <MKTypography
                          component={Link}
                          to={route}
                          variant="button"
                          fontWeight="regular"
                          textTransform="capitalize"
                        >
                          {name}
                        </MKTypography>
                      )}
                    </MKBox>
                  ))}
                </MKBox>
              </Grid>
            ))}
          </Grid>
        </Container>
      </MKBox>
      <div className="container-fluid mx-0 px-0">
        <div className={Cx.copyrightline}>{copyright}</div>
      </div>
    </>
  );
}
DefaultFooter.propTypes = {
  content: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.array])).isRequired,
};

export default DefaultFooter;
