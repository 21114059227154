import React, { useEffect, useState } from "react";
import DefaultFooter from "examples/Footers/DefaultFooter";
import footerRoutes from "footer.routes";
import Header from "layouts/hearder/Header";
import Sidebar from "layouts/sidebar/sidebar";
import Cx from "./page.module.scss";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Table from "../tabletest/table";
const ReportTable = () => {
  const [data, setData] = useState(null);
  const userId = localStorage.getItem("userId");
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedEndDate, setSelectedEndtDate] = useState("");

  const handleStartDateChange = (event) => {
    setSelectedStartDate(event.target.value);
    localStorage.setItem("selectedStartDate", event.target.value);
  };
  const handleEndDateChange = (event) => {
    setSelectedEndtDate(event.target.value);
    localStorage.setItem("selectedEndDate", event.target.value);
  };

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://api.agfinancehub.com/api/XeroList/?user_id=${userId}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [userId]);

  const profitAndLossReports = (data && data[0]?.data?.profit_and_loss) || [];

  const isFormValid = selectedStartDate && selectedEndDate;
  return (
    <>
      <Header activeClass="Report Table" />
      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3" style={{ paddingLeft: "0px", top: "-3px" }}>
              <Sidebar />
            </div>

            <div className="col-lg-9">
              <div className={`${Cx.tableheading} gap-2 d-md-flex d-block`}>
                <div className="d-md-flex d-block gap-2 date_input_field">
                  <div className="d-flex justify-content-center align-items-center gap-2 mb-md-0 mb-2">
                    <span style={{ whiteSpace: "nowrap", fontSize: "15px" }}>Start Date</span>
                    <input
                      type="date"
                      className="form-control"
                      required
                      onChange={handleStartDateChange}
                    />
                  </div>
                  <div className="d-flex justify-content-center align-items-center gap-2 mb-md-0 mb-2">
                    <span style={{ whiteSpace: "nowrap", fontSize: "15px" }}>End Date</span>{" "}
                    <input
                      type="date"
                      className="form-control"
                      required
                      onChange={handleEndDateChange}
                    />
                  </div>
                </div>
                <Link
                  className={Cx.btnsyncdata}
                  to={
                    "https://login.xero.com/identity/connect/authorize?response_type=code&client_id=AF64D25746694AFF9B97EC735E4C6A1E&redirect_uri=https%3A//web.agfinance.io/&scope=openid%20profile%20email%20offline_access%20accounting.transactions%20accounting.reports.read%20accounting.settings"
                  }
                  target="_blank"
                  onClick={(e) => {
                    if (!isFormValid) {
                      e.preventDefault();
                      toast.error("Please fill in both Start Date and End Date.");
                    }
                  }}
                >
                  {profitAndLossReports && profitAndLossReports?.length == 0
                    ? "sync Data"
                    : "Resync Data"}
                </Link>
              </div>
              <div className={Cx.reporttablesection}>
                <div className="conatiner">
                  <div className="row mx-0 px-0">
                    <div className="col-12 mx-auto">
                      <div className={Cx.main_dataarea}>
                        <h2>Profit and Loss Reports</h2>
                        <Table />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <DefaultFooter content={footerRoutes} />
        </div>
      </section>
    </>
  );
};

export default ReportTable;
