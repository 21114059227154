import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import Cx from "./page.module.scss";
import { useNavigate } from "react-router-dom";
/* import { IoArrowBackOutline } from "react-icons/io5"; */
import DefaultFooter from "examples/Footers/DefaultFooter";
import footerRoutes from "footer.routes";
// import { faEuroSign } from "@fortawesome/free-solid-svg-icons";
import { faPoundSign } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import Sidebar from "layouts/sidebar/sidebar";
import Header from "layouts/hearder/Header";

const WelcomeQuestion = () => {
  const id = localStorage.getItem("userId");
  const { register, handleSubmit, setValue } = useForm();
  const navigate = useNavigate();

  const AMOUNT_CHOICES = [
    { value: "10k_25k", label: "£10,000 - £25,000" },
    { value: "25k_50k", label: "£25,000 - £50,000" },
    { value: "50k_100k", label: "£50,000 - £100,000" },
    { value: "100k_150k", label: "£100,000 - £150,000" },
    { value: "150k_250k", label: "£150,000 - £250,000" },
    { value: "250k_500k", label: "£250,000 - £500,000" },
    { value: "500k_1m", label: "£500,000 - £1 million" },
    { value: "1m_3m", label: "£1 million - £3 million" },
    { value: "3m_5m", label: "£3 million - £5 million" },
    { value: "5m_10m", label: "£5 million - £10 million" },
    { value: "10m_20m", label: "£10 million - £20 million" },
  ];

  const CATEGORY_CHOICES = [
    { value: "business_loan", label: "Business Loan" },
    { value: "acquiring_business", label: "Acquiring a Business" },
    { value: "leasing_vehicle_equipment", label: "Leasing a Company Vehicle or Equipment" },
    { value: "purchasing_vehicle_equipment", label: "Purchasing a Company Vehicle or Equipment" },
    { value: "vat_corporate_tax_financing", label: "Financing for VAT or Corporate Tax Payments" },
    { value: "cash_flow_solutions", label: "Cash Flow Solutions" },
    { value: "invoice_funding", label: "Invoice Funding" },
    { value: "asset_based_financing", label: "Asset-Based Financing" },
    { value: "merchant_cash_advance", label: "Merchant Cash Advance" },
    { value: "property_finance", label: "Property Finance" },
    { value: "commercial_mortgage", label: "Commercial Mortgage" },
    { value: "other", label: "Other" },
  ];

  const getAns = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}user/${id}/`);
      if (response) {
        setValue("turnover", response?.data?.annual_turnover);
        setValue("totalFinancing", response?.data?.total_finance_amount);
        setValue("financeReason", response?.data?.reasonforfinance);
      }
      console.log(response.data, "Updated Data");
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  useEffect(() => {
    getAns();
  }, []);

  const onSubmit = async (formData) => {
    try {
      const response = await axios.patch(`${process.env.REACT_APP_BASE_URL}user/${id}/`, {
        annual_turnover: formData?.turnover || null,
        total_finance_amount: formData?.totalFinancing || null,
        reasonforfinance: formData?.financeReason || null,
      });

      if (response) {
        navigate("/upload-business-document");
        getAns();
      }
      console.log(response.data, "Updated Data");
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  return (
    <>
      <Header activeClass="Welcome Call Questions" />
      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3" style={{ paddingLeft: "0px", top: "-3px" }}>
              <Sidebar activeClass="Welcome Call Questions" />
            </div>
            <div className="col-lg-9 m-auto">
              <div className={Cx.analysisSection}>
                <div className={Cx.technicalanalysis}>
                  {/*   <Link to="/dashboard-business" className={Cx.backbtn}>
                    <IoArrowBackOutline />
                  </Link> */}
                  <p className={Cx.introText}>
                    This questionnaire will take just a couple of minutes to complete and will help
                    us gain insight into your business ahead of your complimentary discovery call
                    with us.
                  </p>
                </div>

                <form onSubmit={handleSubmit(onSubmit)} className={Cx.technicalanalysismain}>
                  <div className={Cx.formGroup}>
                    <div className={Cx.inputGroup}>
                      <label htmlFor="turnover">Annual turnover for the last 12 months?</label>
                      <div className={Cx.inputIcon}>
                        {/* <FontAwesomeIcon icon={faEuroSign} className={Cx.icon} /> */}
                        <FontAwesomeIcon icon={faPoundSign} className={Cx.icon} />
                        <input
                          type="number"
                          id="turnover"
                          name="turnover"
                          placeholder="£"
                          className={Cx.formControl}
                          {...register("turnover")}
                        />
                      </div>
                    </div>

                    <div className={Cx.inputGroup}>
                      <label htmlFor="totalFinancing">
                        Total amount of financing the company is seeking?
                      </label>
                      <select
                        id="totalFinancing"
                        name="totalFinancing"
                        className={Cx.formControl}
                        {...register("totalFinancing")}
                      >
                        <option value="">Select</option>
                        {AMOUNT_CHOICES.map((item, index) => (
                          <option value={item.value} key={index}>
                            {item.label}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className={Cx.inputGroup}>
                      <label htmlFor="financeReason">Reason for finance?</label>
                      <select
                        id="financeReason"
                        name="financeReason"
                        className={Cx.formControl}
                        {...register("financeReason")}
                      >
                        {CATEGORY_CHOICES.map((item, index) => (
                          <option value={item.value} key={index}>
                            {item.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <button type="submit" className={Cx.submitBtn}>
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div>
          <DefaultFooter content={footerRoutes} />
        </div>
      </section>
    </>
  );
};

export default WelcomeQuestion;
