// @mui material components

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Cx from "../../../style/custom.module.scss";
import footerRoutes from "footer.routes";
import Headerhome from "layouts/hearderhome/Headerhome";
import DefaultFooter from "examples/Footers/DefaultFooter";

function PrivacyPolicy() {
  return (
    <>
      <Headerhome />
      <section className={`${Cx.section_about} position-relative`}>
        <div className="container-fluid mx-0 px-0">
          <div className="container m-auto">
            <div className={Cx.headerpart}>
              <div className="row m-auto">
                <h1>Privacy Policy</h1>
                <h2>AG Finance and Technology Group Ltd</h2>
                <p>for www.agfinancehub.com and the mobile application ‘AG Finance Hub’</p>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className={Cx.abutotextarea}>
                <MKTypography variant="body1" className={Cx.heading_textarea}>
                  <h3>1. Introduction</h3>

                  <p>
                    1.1 We are committed to safeguarding the privacy of our users. This policy is
                    designed to ensure that we safely handle your personal data in accordance with
                    relevant regulations and legislation such as Data Protection Act 2018 and the UK
                    General Data Protection Regulations (GDPR) (the Data Protection Regulations).
                  </p>

                  <p>
                    1.2 This policy applies in those cases where we act as a data controller for the
                    personal data of our website visitors and service users. This means those cases
                    where we can decide the purposes and method of processing your personal data.
                  </p>

                  <p>
                    1.3 By using our website or mobile application ‘AG Finance Hub’ you are agreeing
                    to the terms of this policy.
                  </p>

                  <p>
                    1.4 These privacy rules explain what data we may collect from you, what we will
                    do with that data and explain how you can limit the publication of your
                    information and how you can choose whether or not you would like to receive
                    direct marketing communications.
                  </p>

                  <p>
                    1.5 In this policy, we, us and our refer to AG FINANCE AND TECHNOLOGY GROUP LTD.
                    Further details about us can be found below, in section 10 of this Privacy
                    Policy.
                  </p>

                  <p>
                    1.6 We reserve the right to update and make changes to this Privacy Policy from
                    time to time. You should check back regularly to ensure that you are up to date
                    with any changes to this policy. Any changes posted will have effect from the
                    date of such posting.
                  </p>
                </MKTypography>

                <MKTypography variant="body1" className={Cx.heading_textarea}>
                  <h3>2. How we use your personal data</h3>

                  <p>
                    2.1 In this Section 2 we set out: (a) the general categories of personal data
                    that we may process; (b) the purposes for which we may process personal data;
                    and (c) the legal basis of the processing in each case.
                  </p>

                  <p>
                    2.2 We may process data about your use of our website www.agfinancehub.co,
                    mobile application ‘AG Finance Hub’ and services (usage data). The usage data
                    may include your IP address, geographical location, browser type and version,
                    operating system, referral source, length of visit, page views and website
                    navigation paths, as well as information about the timing, frequency and pattern
                    of your website, mobile application or service use. The source of the usage data
                    is our analytics tracking system. This usage data may be processed for the
                    purposes of analysing the use of the website, mobile application ‘AG Finance
                    Hub’ and services. The legal basis for this processing is either your specific
                    consent or where we are not legally required to ask for consent, we may process
                    this data for our legitimate interests, namely monitoring and improving our
                    website, mobile application, and services.
                  </p>

                  <p>
                    2.3 We may process your account data (account data). The account data may
                    include your name, email address, contact phone number and postal address, as
                    well as details about you or your company’s financial year end, and any other
                    financial documents or record you may provide. The account data may be processed
                    for the purposes of operating our website, providing our services, ensuring the
                    security of our website and services, maintaining back-ups of our databases, and
                    communicating with you. The legal basis for this processing is either your
                    specific consent or where we are not legally required to ask for consent, we may
                    process this data for our legitimate interests, namely monitoring and improving
                    our website and services.
                  </p>

                  <p>
                    2.4 We may process information relating to transactions, including purchases of
                    goods and services that you enter into with us and/or through our website or
                    mobile application (transaction data). The transaction data may include your
                    contact details, your card details, and the transaction details. The transaction
                    data may be processed for the purpose of supplying goods or services and keeping
                    proper records of those transactions. The legal basis for this processing is the
                    performance of a contract between you and us and/or taking steps, at your
                    request, to enter into such a contract and our legitimate interests, namely our
                    interest in the proper administration of our website and business.
                  </p>

                  <p>
                    2.5 We may process any of your personal data identified in this policy where
                    necessary for administrative purposes including in the exercise or defense of
                    legal claims. The legal basis for this processing is our legitimate interests,
                    namely for administrative record-keeping, processing transactions, and
                    maintaining business records or for the protection and assertion of our legal
                    rights.
                  </p>

                  <p>
                    2.6 If you supply any other person personal data to us, you must do so only if
                    you have the authority of such person to do so and you must comply with any
                    obligations imposed upon you under the Data Protection Regulations.
                  </p>

                  <h3>3. Providing your personal data to others</h3>

                  <p>
                    3.1 We may disclose your personal data to any member of our group of companies
                    or connected company’s (this means our subsidiaries, our holding company and its
                    subsidiaries, or companies which will in the near future form part of the same
                    group of companies) insofar as reasonably necessary for the purposes, and on the
                    legal bases, set out in this policy.
                  </p>

                  <p>
                    3.2 We may disclose your personal data to our insurers and/or professional
                    advisers and/or service providers insofar as reasonably necessary for the
                    purposes of obtaining or maintaining insurance coverage, managing risks,
                    obtaining professional advice, or to exercise or defend legal claims.
                  </p>

                  <p>
                    3.3 We may pass your personal information to credit reference agencies or other
                    agencies that provide services to verify your identity or for any other checks
                    or searches required by legislation or our regulators relating to money
                    laundering. These agencies may keep a record of any search that they do.
                  </p>

                  <p>
                    3.4 If you carry out financial transactions through our website, application, or
                    services these services will be handled by our payment services providers. We
                    share transaction data with our payment services providers to the extent
                    necessary for the purposes of processing your payments, refunding such payments,
                    and dealing with complaints and queries relating to such payments and refunds.
                  </p>

                  <p>
                    3.5 We may outsource or contract the provision of IT services to third parties.
                    If we do, those third parties may hold and process your personal data. In these
                    circumstances, we will require that the IT supplier only processes your personal
                    data for us, as directed by us, and in accordance with the Data Protection
                    Regulations.
                  </p>

                  <p>
                    3.6 We may outsource the provision of business financing services and share your
                    documents and details with brokers or providers of other financial services, in
                    instances whereby your company requests services for business finance solutions
                    from us and to deliver such services we need to share your documents and
                    details. Our corporate partners, strategic partners, or service providers may
                    share such data with their panel of lenders in order to provide you with any
                    financing options. Your data will be stored, held, and processed as per our
                    corporate partners, strategic partners, or service providers terms of use.
                  </p>

                  <p>
                    3.7 If we sell all or part of our business, we may pass your personal data to
                    the purchaser. In these circumstances, we will require the purchaser to contact
                    you after completion of the sale to inform you of the identity of the purchaser.
                  </p>

                  <p>
                    3.8 In addition to the specific disclosures of personal data set out in this
                    Section 3, we may disclose your personal data where such disclosure is necessary
                    for compliance with a legal obligation to which we are subject, or in order to
                    protect your legal interests or the legal interests of another person.
                  </p>
                </MKTypography>
                <MKTypography variant="body1" className={Cx.heading_textarea}>
                  <h3>4. International transfers of your personal data</h3>

                  <p>
                    4.1 In this Section 4, we provide information about the circumstances in which
                    your personal data may be transferred to countries outside the European Economic
                    Area (EEA).
                  </p>

                  <p>
                    4.2 Unless such transfer is made with your consent, or is required in order to
                    fulfill the terms of any services requested from us, we will not transfer any of
                    your personal data to any country outside the EEA unless such transfer is to an
                    organisation which provides adequate safeguards in compliance with the Data
                    Protection Regulations.
                  </p>

                  <p>
                    4.3 You acknowledge that personal data that you submit for publication through
                    our website, mobile application or services may be available, via the internet,
                    around the world. We cannot prevent the use (or misuse) of such personal data by
                    others.
                  </p>

                  <h3>5. Retaining and deleting personal data</h3>

                  <p>
                    5.1 This Section 5 sets out our data retention policies and procedures, which
                    are designed to help ensure that we comply with our legal obligations in
                    relation to the retention and deletion of personal data.
                  </p>

                  <p>
                    5.2 Personal data that we process for any purpose shall not be kept for longer
                    than is necessary for that purpose. This means that unless there is a good
                    reason to do so we won keep your personal data more than 6 years after our
                    business relationship has ended.
                  </p>

                  <p>
                    5.3 Notwithstanding the other provisions of this Section 5, we may retain your
                    personal data where such retention is necessary for compliance with a legal
                    obligation to which we are subject, or in order to protect your legal interests
                    or the legal interests of another person.
                  </p>

                  <h3>6. Amendments</h3>

                  <p>
                    6.1 We may update this policy from time to time by publishing a new version on
                    our website.
                  </p>

                  <p>
                    6.2 You should check this page occasionally to ensure you are happy with any
                    changes to this policy.
                  </p>

                  <p>
                    6.3 We may notify you of changes to this policy by email or through the private
                    messaging system on our website.
                  </p>
                </MKTypography>
                <MKTypography variant="body1" className={Cx.heading_textarea}>
                  <h3>7. Your rights</h3>

                  <p>
                    7.1 In this Section 7, we have summarised the rights that you have under data
                    protection law. Some of the rights are complex, and not all of the details have
                    been included in our summaries. Accordingly, you should read the relevant laws
                    and guidance from the regulatory authorities for a full explanation of these
                    rights.
                  </p>

                  <p>7.2 Your principal rights under data protection law are:</p>
                  <ul>
                    <li>The right to access;</li>
                    <li>The right to rectification;</li>
                    <li>The right to erasure;</li>
                    <li>The right to restrict processing;</li>
                    <li>The right to object to processing;</li>
                    <li>The right to data portability;</li>
                    <li>The right to complain to a supervisory authority; and</li>
                    <li>The right to withdraw consent.</li>
                  </ul>

                  <p>
                    7.3 You have the right to confirmation as to whether or not we process your
                    personal data and, where we do, access to the personal data, together with
                    certain additional information. That additional information includes details of
                    the purposes of the processing, the categories of personal data concerned and
                    the recipients of the personal data. Providing the rights and freedoms of others
                    are not affected, we will supply to you a copy of your personal data, as
                    described below (clause 7.13).
                  </p>

                  <p>
                    7.4 You have the right to have any inaccurate personal data about you rectified
                    and, taking into account the purposes of the processing, to have any incomplete
                    personal data about you completed.
                  </p>

                  <p>
                    7.5 In some circumstances you have the right to the erasure of your personal
                    data without undue delay. Those circumstances include: the personal data is no
                    longer necessary in relation to the purposes for which it was collected or
                    otherwise processed; you withdraw consent to consent-based processing; you
                    object to the processing under certain rules of applicable data protection law;
                    the processing is for direct marketing purposes; and the personal data have been
                    unlawfully processed. However, there are exclusions of the right to erasure. The
                    general exclusions include where processing is necessary: for exercising the
                    right of freedom of expression and information; for compliance with a legal
                    obligation; or for the establishment, exercise or defence of legal claims.
                  </p>

                  <p>
                    7.6 In some circumstances you have the right to restrict the processing of your
                    personal data. Those circumstances are: you contest the accuracy of the personal
                    data; processing is unlawful but you oppose erasure; we no longer need the
                    personal data for the purposes of our processing, but you require personal data
                    for the establishment, exercise or defence of legal claims; and you have
                    objected to processing, pending the verification of that objection. Where
                    processing has been restricted on this basis, we may continue to store your
                    personal data. However, we will only otherwise process it: with your consent;
                    for the establishment, exercise or defence of legal claims; for the protection
                    of the rights of another natural or legal person; or for reasons of important
                    public interest.
                  </p>

                  <p>
                    7.7 You have the right to object to our processing of your personal data on
                    grounds relating to your particular situation, but only to the extent that the
                    legal basis for the processing is that the processing is necessary for: the
                    performance of a task carried out in the public interest or in the exercise of
                    any official authority vested in us; or the purposes of the legitimate interests
                    pursued by us or by a third party. If you make such an objection, we will cease
                    to process the personal information unless we can demonstrate compelling
                    legitimate grounds for the processing which override your interests, rights and
                    freedoms, or the processing is for the establishment, exercise or defence of
                    legal claims.
                  </p>

                  <p>
                    7.8 You have the right to object to our processing of your personal data for
                    direct marketing purposes (including profiling for direct marketing purposes).
                    If you make such an objection, we will cease to process your personal data for
                    this purpose.
                  </p>

                  <p>
                    7.9 You have the right to object to our processing of your personal data for
                    scientific or historical research purposes or statistical purposes on grounds
                    relating to your particular situation, unless the processing is necessary for
                    the performance of a task carried out for reasons of public interest.
                  </p>

                  <p>
                    7.10 To the extent that the legal basis for our processing of your personal data
                    is:
                  </p>
                  <ul>
                    <li>Consent; or</li>
                    <li>
                      That the processing is necessary for the performance of a contract to which
                      you are party or in order to take steps at your request prior to entering into
                      a contract,
                    </li>
                  </ul>
                  <p>
                    and such processing is carried out by automated means, you have the right to
                    receive your personal data from us in a structured, commonly used and
                    machine-readable format. However, this right does not apply where it would
                    adversely affect the rights and freedoms of others.
                  </p>

                  <p>
                    7.11 If you consider that our processing of your personal information infringes
                    data protection laws, you have a legal right to lodge a complaint with a
                    supervisory authority responsible for data protection. You may do so in the EU
                    member state of your habitual residence, your place of work or the place of the
                    alleged infringement.
                  </p>

                  <p>
                    7.12 To the extent that the legal basis for our processing of your personal
                    information is consent, you have the right to withdraw that consent at any time.
                    Withdrawal will not affect the lawfulness of processing before the withdrawal.
                  </p>

                  <p>
                    7.13 You may request that we provide you with any personal information we hold
                    about you. Provision of this information will be subject to the supply of
                    appropriate evidence of your identity (for this purpose, we will usually accept
                    a photocopy of your passport certified by a solicitor or bank plus an original
                    copy of a utility bill showing your current address).
                  </p>
                </MKTypography>
                <MKTypography variant="body1" className={Cx.heading_textarea}>
                  <h3>8. About cookies</h3>

                  <p>
                    8.1 A cookie is a small file containing an identifier (a string of letters and
                    numbers) that is sent by a web server to a web browser asking permission to be
                    placed on your computers or web devices (such as mobile or tablet) hard drive.
                    The file is added and the cookie helps analyse web traffic or lets you know when
                    you visit a particular site. Cookies allow web applications to respond to you as
                    an individual. The web application can tailor its operations to your needs,
                    likes and dislikes by gathering and remembering information about your
                    preferences.
                  </p>

                  <p>
                    8.2 Cookies may be either persistent cookies or session cookies: a persistent
                    cookie will be stored by a web browser and will remain valid until its set
                    expiry date, unless deleted by the user before the expiry date; a session
                    cookie, on the other hand, will expire at the end of the user session, when the
                    web browser is closed.
                  </p>

                  <p>
                    8.3 Cookies do not typically contain any information that personally identifies
                    a user, but personal information that we store about you may be linked to the
                    information stored in and obtained from cookies.
                  </p>

                  <h3>9. Cookies that we use</h3>

                  <p>
                    9.1 We use traffic log cookies to identify which pages are being used. This
                    helps us analyse data about web page traffic and improve our services in order
                    to tailor them to customer needs. We only use this information for statistical
                    analysis purposes and then the data is removed from the system.
                  </p>

                  <p>
                    9.2 Overall, cookies help us provide you with a better experience, by enabling
                    us to monitor which pages you find useful and which you do not. A cookie in no
                    way gives us access to your computer or any information about you, other than
                    the data you choose to share with us.
                  </p>

                  <p>
                    9.3 You can choose to accept or decline cookies. Most web browsers automatically
                    accept cookies, but you can usually modify your browser setting to decline
                    cookies if you prefer. This may prevent you from taking full advantage of our
                    services.
                  </p>

                  <p>
                    9.4 We may use Google Analytics to analyse the use of our website. Google
                    Analytics gathers information about website use by means of cookies. The
                    information gathered relating to our website is used to create reports about the
                    use of our website. Googles privacy policy can be found at the following web
                    address:
                    <a
                      href="https://www.google.com/policies/privacy/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "#efb300" }}
                    >
                      https://www.google.com/policies/privacy/
                    </a>
                    .
                  </p>

                  <h3>10. Our details</h3>

                  <p>
                    10.1 This website is owned and operated by AG FINANCE AND TECHNOLOGY GROUP LTD.
                  </p>

                  <p>
                    10.2 Our registered office address is 71-75 Shelton Street, Covent Garden,
                    London, United Kingdom, WC2H 9JQ and our company registration number is
                    15901932.
                  </p>

                  <p>10.3 You can contact us:</p>
                  <ul>
                    <li>By post, to the postal address given above; or</li>
                    <li>
                      By email, using the email address published on our website from time to time.
                    </li>
                  </ul>

                  <p>
                    10.4 Unless we hear from you in relation to this privacy policy, which will be
                    available for you to read and access on our website and mobile application, AG
                    FINANCE AND TECHNOLOGY GROUP LTD will process your data on the basis of your
                    agreement with this policy.
                  </p>
                </MKTypography>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid px-0 mx-0 ">
          <div className={Cx.bannertopimage1}>
            <div className={Cx.paddingarea1}></div>
          </div>
        </div>
      </section>

      <MKBox px={0}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default PrivacyPolicy;
